import { ThemeProvider } from 'styled-components';
import './App.css';
import AppBar from './controls/AppBar';
import Text from './controls/Text';

const theme = {
  pc: '#123456',
  sc: 'green',
  ff: 'Arial, Helvetica, sans-serif',
  fss: '24px',
  td: 'white',
  tl: '#222',
  bg: 'white',

}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar>
        <Text size='28px' colour='white' bold>Globesoft</Text>
      </AppBar>
      <div style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
        <Text size='16px'>GlobeSoft is an IT Consulting company that specialises in software design and development.</Text>
        <div style={{padding: '10px 0 0 10px', display: 'flex', flexDirection: 'column'}}>
          <Text size='16px'>- System Architecture Design</Text>
          <Text size='16px'>- System Analysis and Design</Text>
          <Text size='16px'>- Customised Application Development (web, mobile and desktop)</Text>
        </div>
        <div style={{padding: '40px 0 0', display: 'flex', flexDirection: 'column'}}>
        <Text size='16px'>Contact:  info@globesoft.co.za</Text>
        </div>
      </div>

    </ThemeProvider>
  );
}

export default App;

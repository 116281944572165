import styled from "styled-components";

const AppBar = styled.div`
display: flex;
background: ${props => props.theme.pc ?? 'black'};
color: ${props => props.theme.td ?? 'grey'};
height: 60px;
align-items: center;
justify-content: center;
`;

export default AppBar;